/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */

import { Pipe, PipeTransform } from '@angular/core';
import { isValidPhoneNumber, parsePhoneNumber } from 'libphonenumber-js';
import { CallbackUtil } from '../../call/util/callback-util';
import { Ringdown } from '../../directory/directory-ringdowns-table/Ringdown';

@Pipe({
    name: 'phoneDisplayFormat'
})
export class PhoneDisplayFormatPipe implements PipeTransform {
    transform(callback: string, ringdowns: Ringdown[] = []): string {
        let result = CallbackUtil.parseUserFromSipUri(callback);
        const ringdown = ringdowns.find((ringdown: Ringdown) => result.includes(ringdown.did));
        if (ringdown) {
            return ringdown.name;
        }
        result = result === 'anonymous' ? 'Anonymous' : result;
        return isValidPhoneNumber(result, 'US') ? parsePhoneNumber(result, 'US').formatNational() : result;
    }
}
