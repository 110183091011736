/*
 * COPYRIGHT Motorola Solutions, INC.
 * ALL RIGHTS RESERVED.
 * MOTOROLA SOLUTIONS CONFIDENTIAL RESTRICTED
 */

import { createReducer, on } from '@ngrx/store';
import {
    acdAnswerComplete,
    acdAnswerCompleteFailed,
    acdAnswerRequest,
    acdAnswerRequestFail,
    acdBid,
    acdBidFail,
    acdBidRemove,
    acdBidResponse,
    acdRejection,
    acdStatusChangeSuccess,
    deleteUserAuthenticationRecord,
    fetchUserAuthenticationRecordsSuccess,
    initUser,
    languageSelect,
    newUserAuthenticationRecord,
    remoteLogoutEvent,
    removeIgnoredAcdCall,
    requestedAcdStatusChange,
    setIgnoredAcdCalls,
    setRole,
    updateApplicationPresence,
    updateUserAuthenticationRecord,
    updateUserSubscriptions
} from './user.actions';
import { AgentStatusRecord, CadLogoutRequest, UserAuthenticationRecord } from 'CalltakingCoreApi';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { BidResult } from '../model/bid-result';
import { VestaKeycloakTokenParsed } from '../model/vesta-keycloak-token-parsed';
import { SortFunctions } from '../../call/util/sort-functions';
import * as dayjs from 'dayjs';
import * as duration from 'dayjs/plugin/duration';
import { fetchAvailableLanguagesSuccess } from '../../configuration/+state/configuration.actions';
import { RequestedAgentStatus } from '../model/requested-agent-status';

dayjs.extend(duration);

export interface UserState extends EntityState<UserAuthenticationRecord> {
    subscriptions: { [topic: string]: { [receipt: string]: boolean } };
    applicationPresence: {
        loggedIn: boolean;
        loginTime?: number;
    };
    token: string | null;
    tokenParsed: VestaKeycloakTokenParsed | undefined;
    agentStatus: AgentStatusRecord | null;
    requestedAgentStatus: RequestedAgentStatus;
    pendingWrapUpTime: number;
    notReadyOnRelease: boolean;
    pendingBid: string | null;
    pendingAcdAnswer: { callId: string; nenaId: string; clusterName: string } | undefined;
    mostRecentlyAnsweredAcdNenaCallId: string | undefined;
    bids: BidState;
    acdIgnoredCalls: {};
    queues: string[];
    supervisedQueues: string[];
    supervisorQueue: string | undefined;
    tokenRoles: string[];
    selectedRole: string | undefined;
    language: string;
    agentFilters: string[];
    permissions: string[];
    supervisedRoles: string[];
    autoLogoffRequest: CadLogoutRequest | undefined;
    agencyName: string;
    agencyFqdn: string;
    supportedLanguages: string[];
}

export interface BidState extends EntityState<BidResult> {}

export const bidAdapter: EntityAdapter<BidResult> = createEntityAdapter<BidResult>({
    selectId: (bidResult: BidResult) => bidResult.callId,
    sortComparer: SortFunctions.bidSort
});

export const adapter: EntityAdapter<UserAuthenticationRecord> = createEntityAdapter<UserAuthenticationRecord>({
    selectId: (authenticationRecord: UserAuthenticationRecord) => authenticationRecord.uuid
});

const initialBidState: BidState = bidAdapter.getInitialState({});

const initialState: UserState = adapter.getInitialState({
    subscriptions: {},
    applicationPresence: {
        loggedIn: false
    },
    token: null,
    tokenParsed: undefined,
    agentStatus: null,
    requestedAgentStatus: {
        status: 'NOT_READY',
        isAgentRequested: false
    },
    pendingWrapUpTime: 0,
    notReadyOnRelease: false,
    pendingBid: null,
    pendingAcdAnswer: undefined,
    mostRecentlyAnsweredAcdNenaCallId: undefined,
    bids: initialBidState,
    acdIgnoredCalls: {},
    queues: [],
    supervisedQueues: [],
    supervisorQueue: undefined,
    tokenRoles: [],
    selectedRole: undefined,
    language: 'en-US',
    agentFilters: [],
    permissions: [],
    supervisedRoles: [],
    autoLogoffRequest: undefined,
    agencyName: '',
    agencyFqdn: '',
    supportedLanguages: []
});

export const userReducer = createReducer(
    initialState,
    on(updateUserSubscriptions, (state, { subscriptions }): UserState => {
        return { ...state, subscriptions: subscriptions };
    }),
    on(initUser, (state, { token, tokenParsed }): UserState => {
        let preferredRole = tokenParsed?.preferred_role ? tokenParsed?.preferred_role : tokenParsed?.roles?.[0];
        let selectedRole = state.selectedRole ? state.selectedRole : preferredRole;
        let queues = tokenParsed?.preferred_role_queues ? tokenParsed?.preferred_role_queues : [];
        let supervisedQueues = tokenParsed?.preferred_role_supervised_queues ? tokenParsed?.preferred_role_supervised_queues : [];
        let permissions = tokenParsed && tokenParsed.preferred_role_permissions ? tokenParsed?.preferred_role_permissions : [];
        let tokenRoles = tokenParsed && tokenParsed.roles ? tokenParsed.roles : [];
        let supervisedRoles = tokenParsed && tokenParsed.preferred_role_supervised_roles ? tokenParsed.preferred_role_supervised_roles : [];
        let supervisorQueue = tokenParsed?.preferred_role_supervisor_queue ? JSON.parse(tokenParsed?.preferred_role_supervisor_queue)[0] : undefined;
        let agencyName = tokenParsed?.agency_name;
        let agencyFqdn = tokenParsed?.agency_fqdn;
        return {
            ...state,
            token: token,
            tokenParsed: tokenParsed,
            tokenRoles: tokenRoles ? tokenRoles : [],
            language: navigator.language,
            queues: queues,
            supervisedQueues: supervisedQueues,
            selectedRole: selectedRole,
            permissions: permissions,
            supervisorQueue: supervisorQueue,
            supervisedRoles,
            agencyName,
            agencyFqdn
        };
    }),
    on(languageSelect, (state, { language }): UserState => {
        return { ...state, language: language };
    }),
    on(updateApplicationPresence, (state, { loggedIn, loginTime }): UserState => {
        return { ...state, applicationPresence: { loggedIn: loggedIn, loginTime: loginTime } };
    }),
    on(newUserAuthenticationRecord, updateUserAuthenticationRecord, (state, { userAuthenticationRecord }) => {
        let existingEntity = state.entities[userAuthenticationRecord.uuid];
        if (!existingEntity || dayjs(existingEntity.updatedTimestamp).isBefore(dayjs(userAuthenticationRecord.updatedTimestamp))) {
            return adapter.setOne(userAuthenticationRecord, state);
        }
        return state;
    }),
    on(deleteUserAuthenticationRecord, (state, { userAuthenticationRecord }) => {
        let existingEntity = state.entities[userAuthenticationRecord.uuid];
        if (existingEntity) {
            return adapter.removeOne(userAuthenticationRecord.uuid, state);
        }
        return state;
    }),
    on(fetchUserAuthenticationRecordsSuccess, (state, { clusterName, users }) => {
        let newUsersMap = Object.fromEntries(users.map((user) => [user.uuid, user]));
        let usersToRemove = Object.values(state.entities)
            .filter(
                (existingUser) =>
                    existingUser.clusterName === clusterName &&
                    (!newUsersMap[existingUser.uuid] || dayjs(newUsersMap[existingUser.uuid].updatedTimestamp).isAfter(dayjs(existingUser.updatedTimestamp)))
            )
            .map((user) => user.uuid);
        return adapter.upsertMany(users, adapter.removeMany(usersToRemove, state));
    }),
    on(acdBid, (state, { bid }): UserState => {
        return { ...state, pendingBid: bid.callId };
    }),
    on(acdBidResponse, (state, { result }) => {
        return {
            ...state,
            bids: bidAdapter.upsertOne(result, state.bids),
            pendingBid: null,
            pendingWrapUpTime: result.queueACD?.wrapup ? dayjs.duration(result.queueACD.wrapup).as('milliseconds') : 0,
            notReadyOnRelease: Boolean(result.queueACD?.notReadyOnRelease)
        };
    }),
    on(acdBidFail, (state) => {
        return { ...state, pendingBid: null };
    }),
    on(acdAnswerRequest, (state, { bid }) => {
        return { ...state, pendingAcdAnswer: { callId: bid.callId, nenaId: bid.nenaId, clusterName: bid.clusterName } };
    }),
    on(acdAnswerComplete, (state, { nenaId }) => {
        return { ...state, pendingAcdAnswer: undefined, mostRecentlyAnsweredAcdNenaCallId: nenaId };
    }),
    on(acdAnswerRequestFail, acdAnswerCompleteFailed, (state): UserState => {
        return { ...state, pendingAcdAnswer: undefined };
    }),
    on(acdBidRemove, (state, { callId }) => {
        return { ...state, bids: bidAdapter.removeOne(callId, state.bids) };
    }),
    on(acdRejection, (state, { event }): UserState => {
        if (state.bids.entities[event.callId]) {
            let bidToReject = { ...state.bids.entities[event.callId] } as BidResult;
            bidToReject.result = 'ASSIGNEE_GONE';
            let notReadyOnFailure = bidToReject.queueACD?.answerMethod.notReadyOnFailure;
            return {
                ...state,
                bids: bidAdapter.upsertOne(bidToReject, state.bids),
                requestedAgentStatus: notReadyOnFailure ? { status: 'NOT_READY', isAgentRequested: false } : state.requestedAgentStatus
            };
        }
        return { ...state };
    }),
    on(setIgnoredAcdCalls, (state, { calls }): UserState => {
        let ignoredCalls: any = { ...state.acdIgnoredCalls };
        calls.forEach((c) => (ignoredCalls[c] = c));
        return { ...state, acdIgnoredCalls: ignoredCalls };
    }),
    on(removeIgnoredAcdCall, (state, { callId }): UserState => {
        let ignoredCalls: any = { ...state.acdIgnoredCalls };
        delete ignoredCalls[callId];
        return { ...state, acdIgnoredCalls: ignoredCalls };
    }),
    on(acdStatusChangeSuccess, (state, { agentStatus }): UserState => {
        return {
            ...state,
            agentStatus: agentStatus,
            requestedAgentStatus: agentStatus.status === 'WRAPUP' ? { status: 'WRAPUP', isAgentRequested: false } : state.requestedAgentStatus
        };
    }),
    on(requestedAcdStatusChange, (state, { agentStatus, isAgentRequested }): UserState => {
        return { ...state, requestedAgentStatus: { status: agentStatus, isAgentRequested: isAgentRequested }};
    }),
    on(setRole, (state, { role }): UserState => {
        return { ...state, selectedRole: role };
    }),
    on(remoteLogoutEvent, (state, { logoutRequest }): UserState => {
        return { ...state, autoLogoffRequest: logoutRequest };
    }),
    on(fetchAvailableLanguagesSuccess, (state, { languages }): UserState => {
        return { ...state, supportedLanguages: languages };
    })
);

export const selectBidState = (state: UserState) => state.bids;

export const { selectEntities: selectBidEntities } = bidAdapter.getSelectors();

const { selectEntities, selectAll } = adapter.getSelectors();

export const selectAllUsers = selectAll;
export const selectUserEntities = selectEntities;
