import { AfterViewInit, Component, ElementRef, Input, OnDestroy, ViewChild } from '@angular/core';
import { AbstractDataTransferObject, Call, CallQueue, UserAuthenticationRecord } from 'CalltakingCoreApi';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { selectUserMap, selectUsername } from '../../user/+state/user.selectors';
import { DropdownComponent, MSI_SORT_DIRECTION } from '@msi/cobalt';
import { CallTableData } from '../../supervisor/models/call-table-data';
import { nullSafeSort } from '../../core/model/sortable';
import { selectAllCallbackMap, selectHasActiveVoiceCall } from '../+state/call.selectors';
import { openCall, requestAnswer, requestJoin, requestUnhold, updateSelectedCall } from '../+state/call.actions';
import { SupervisorCallColumn } from '../../settings/model/settings.model';
import { selectCallQueueMap } from '../../configuration/+state/configuration.selectors';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { CallFunctions } from '../util/call-functions';
import { Ringdown } from '../../directory/directory-ringdowns-table/Ringdown';
import { selectRingdowns } from '../../directory/+state/directory.selectors';

@Component({
    selector: 'msi-call-table',
    templateUrl: './call-table.component.html',
    styleUrls: ['./call-table.component.scss']
})
export class CallTableComponent implements AfterViewInit, OnDestroy {
    @Input()
    callFilter: string | undefined;

    @Input()
    public calls: CallTableData[] = [];

    @Input()
    public columns: SupervisorCallColumn[] = [];

    @Input()
    public supervisor = false;

    @ViewChild('table', { read: ElementRef })
    private table: ElementRef;

    @ViewChild('viewport')
    private viewport: CdkVirtualScrollViewport;

    private observer!: ResizeObserver;

    public userMap$: Observable<Record<string, UserAuthenticationRecord>>;
    public queueMap$: Observable<Record<string, CallQueue>>;
    public username$: Observable<string | undefined>;
    public selectedSort = (a: CallTableData, b: CallTableData) => nullSafeSort(a.priorityThreshold, b.priorityThreshold, 'asc');
    public columnSort = (a: SupervisorCallColumn, b: SupervisorCallColumn) => nullSafeSort(a.position, b.position, 'asc');
    public hasConnectedVoiceCall$: Observable<boolean>;
    public callbackMap$: Observable<Record<string, string>>;
    public ringDowns$: Observable<Ringdown[]>;

    constructor(private store: Store) {
        this.callbackMap$ = this.store.select(selectAllCallbackMap);
        this.userMap$ = store.select(selectUserMap);
        this.queueMap$ = store.select(selectCallQueueMap);
        this.username$ = store.select(selectUsername);
        this.hasConnectedVoiceCall$ = store.select(selectHasActiveVoiceCall);
        this.ringDowns$ = store.select(selectRingdowns);
    }

    /* Add manual resize observer on table to bypass eager loading of tab content causing
     the viewport to be initialized when not visible and calculate size incorrectly */
    ngAfterViewInit() {
        this.observer = new ResizeObserver(() => this.viewport.checkViewportSize());
        this.observer.observe(this.table.nativeElement);
    }

    ngOnDestroy() {
        this.observer?.unobserve(this.table.nativeElement);
    }

    public doSort(direction: MSI_SORT_DIRECTION, field: keyof CallTableData | undefined) {
        this.selectedSort = (a: CallTableData, b: CallTableData) => nullSafeSort(field ? a[field] : a, field ? b[field] : b, direction);
    }

    public trackByKey(_: number, col: SupervisorCallColumn) {
        return col.displayName;
    }

    public trackById(index: number, identifiable: AbstractDataTransferObject) {
        return identifiable.uuid;
    }

    public answerCall(call: Call) {
        this.store.dispatch(requestAnswer({ callId: call.uuid, clusterName: call.clusterName }));
    }

    public joinCall(call: Call) {
        this.store.dispatch(requestJoin({ callId: call.uuid, clusterName: call.clusterName }));
    }
    public requestUnhold(call: Call) {
        this.store.dispatch(requestUnhold({ callId: call.uuid, clusterName: call.clusterName }));
    }

    public viewDetails(call: Call, username: string, dropdown: DropdownComponent) {
        if (CallFunctions.isActiveParticipant(call, username)) {
            this.store.dispatch(updateSelectedCall({ callId: call.uuid }));
            return;
        }

        dropdown.close();
        this.store.dispatch(openCall({ callId: call.uuid, clusterName: call.clusterName }));
    }
}
