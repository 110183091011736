<table class="threshold-table" msi-table cdkVirtualScrollingElement #table *ngLet='queueMap$ | async as queueMap'>
    <thead>
    <tr>
        <th msi-table-header
            id="priority-threshold-sort"
            class="sticky-action-col"
            [sortable]="supervisor"
            [style.text-align]="'right'"
            [style.width.px]="supervisor ? 117 : 75"
            [style.min-width.px]="supervisor ? 117 : 75"
            (sort)="doSort($event, 'priorityThreshold')">
            <msi-icon *ngIf="supervisor" class="threshold-header-icon" name="ic_priority_diagnostic"></msi-icon>
        </th>
        <th msi-table-header id="call-list-{{col.key}}-header"
            *ngFor="let col of columns | sort : columnSort; trackBy:trackByKey" [sortable]="true"
            (sort)="doSort($event, $any(col.key))"
            [style.flex]="1"
            [style.min-width.px]="col.minWidth">
            {{ col.abbreviatedName }}
        </th>
    </tr>
    </thead>
    <tbody *ngLet="username$ | async as username">
    <ng-container *ngLet="ringDowns$ | async as ringDowns">
        <ng-container *ngLet="callbackMap$ | async as callbackMap">
            <cdk-virtual-scroll-viewport [itemSize]="48" #viewport *ngLet="userMap$ | async as userMap">
                <tr *cdkVirtualFor="let call of calls | sort: selectedSort | filteredCalls : callFilter : userMap : callbackMap; let i = index; trackBy: trackById">

                    <ng-container *ngLet="call | agentCallStatus : userMap[username] as callStatus">
                        <td class="action-menu sticky-action-col"
                            [style.width.px]="supervisor ? 117 : 75"
                            [style.min-width.px]="supervisor ? 117 : 75">

                            <div class="sticky-col-items">
                                <button id="action-dropdown-btn" class="msi-btn msi-btn-icon"
                                        [msiDropdownTriggerFor]="action">
                                    <msi-icon [size]="32" name="ic_more"></msi-icon>
                                </button>
                            </div>

                            <msi-dropdown #action>
                                <msi-dropdown-item
                                    *ngIf="callStatus === 'CONNECTED' && !(call | isActiveParticipant : username)"
                                    id="join-call-button-{{i}}"
                                    (click)="joinCall(call)">
                                    <div class="title">Join the call</div>
                                </msi-dropdown-item>
                                <msi-dropdown-item id="answer-call-button-{{i}}"
                                                   *ngIf="callStatus === 'RINGING'"
                                                   (click)="answerCall(call)">
                                    <div class="title">Answer the call</div>
                                </msi-dropdown-item>
                                <msi-dropdown-item *ngIf="callStatus === 'ON_HOLD'" (click)="requestUnhold(call)"
                                                   id="hold-call-button-{{i}}">
                                    <div class="title">Unhold the call</div>
                                </msi-dropdown-item>
                                <msi-dropdown-item *ngIf="supervisor" id="view-call-details-btn-{{i}}"
                                                   (click)="viewDetails(call, username, action)">
                                    <p class="title">View the call</p>
                                </msi-dropdown-item>
                            </msi-dropdown>


                        </td>
                    </ng-container>

                    <td *ngFor="let col of columns | sort : columnSort; trackBy:trackByKey"
                        [ngClass]="call.columnThresholds && call.columnThresholds[col.key] ? 'threshold-' + call.columnThresholds[col.key]?.severity?.toLowerCase() : ''"
                        [style.width.px]="col.minWidth"
                        [style.min-width.px]="col.minWidth"
                        [style.flex]="1">
                        <ng-container [ngSwitch]="col.key">

                            <!-- Call Type -->
                            <div *ngSwitchCase="'type'" id="call-type-{{i}}" class="text-overflow">
                                {{ call.type | titlecase }}
                            </div>

                            <!-- Priority Queue -->
                            <div *ngSwitchCase="'priorityQueue'" id="call-priorityQueue-{{i}}" class="text-overflow"
                                 [msiTooltip]='call | queues : queueMap'>
                                {{ call | queues : queueMap }}
                            </div>

                            <!-- Priority Queue -->
                            <div *ngSwitchCase="'callPriority'" id="call-priority-{{i}}" class="text-overflow"
                                 [msiTooltip]='call.priority?.name'>
                                {{ call.priority?.name }}
                            </div>

                            <!-- Callback -->
                            <div *ngSwitchCase="'callback'" id="call-number-{{i}}"

                                 class="callback-container">
                                <msi-icon [size]="24" name="{{ call | callIcon }}"></msi-icon>
                                <span *ngLet="callbackMap[call.uuid] | phoneDisplayFormat: ringDowns as callback"
                                      [msiTooltip]="callback"
                                      class="text-overflow" >{{ callback }}</span>
                            </div>

                            <!-- Priority Agent -->
                            <div *ngSwitchCase="'priorityAgent'" id="call-priorityAgentName-{{i}}"
                                 class="text-overflow">
                                <msi-call-table-participant-info
                                    [participantString]="call | participantList : userMap"></msi-call-table-participant-info>
                            </div>

                            <!-- Position Name -->
                            <div *ngSwitchCase="'positionName'" id="call-positionName-{{i}}" class="text-overflow"
                                 [msiTooltip]="call | positionName">
                                {{ call | positionName }}
                            </div>

                            <!-- Call Status -->
                            <div *ngSwitchCase="'status'" id="call-status-{{i}}" class="text-overflow">
                                <ng-container *ngLet="call | agentCallStatus:call.priorityAgent as status">
                                    {{ status | callStatus : true }}
                                </ng-container>
                            </div>

                            <!-- Call Duration -->
                            <div *ngSwitchCase="'createdTimestamp'" id="call-duration-{{i}}"
                                 class="threshold-container text-overflow">
                                <msi-elapsed-time class="font-mono" [startTime]="call.createdTimestamp"
                                                  [endTime]="call.releasedOn"></msi-elapsed-time>
                            </div>

                            <div *ngSwitchDefault id="call-{{col.key}}-{{i}}" class="text-overflow">
                                {{ call[col.key] }}
                            </div>
                        </ng-container>
                    </td>

                </tr>
            </cdk-virtual-scroll-viewport>
        </ng-container>
    </ng-container>
    </tbody>
</table>

<ng-template #testing let-call="call">
    {{ call.callback }}
</ng-template>
